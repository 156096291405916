import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export interface StoreInfo {
  classes: {
    header?: Array<string>;
    headerContainer?: Array<string>;
    headerMobile?: Array<string>;
    headerMenu?: Array<string>;
    aside?: Array<string>;
    asideMenu?: Array<string>;
    asideToggle?: Array<string>;
    toolbar?: Array<string>;
    toolbarContainer?: Array<string>;
    content?: Array<string>;
    contentContainer?: Array<string>;
    footerContainer?: Array<string>;
    sidebar?: Array<string>;
    pageTitle?: Array<string>;
  };
}

@Module
export default class BodyModule extends VuexModule implements StoreInfo {
  classes = {};
  filterClientsWithTraining;
  myTrainingsCollapsed;
  userSearchInMenu = true;
  pageLoading = false;

  get isPageLoading() {
    return this.pageLoading;
  }

  get showUserSearchInMenu() {
    return this.userSearchInMenu;
  }
  get isMyTrainingsCollapsed() {
    return this.myTrainingsCollapsed == undefined
      ? localStorage.getItem("myTrainingsCollapsed")
        ? localStorage.getItem("myTrainingsCollapsed") === "true"
        : false
      : this.myTrainingsCollapsed;
  }

  get shouldFilterClientsWithTraining() {
    return this.filterClientsWithTraining == undefined
      ? localStorage.getItem("filterClientsWithTraining")
        ? localStorage.getItem("filterClientsWithTraining") === "true"
        : true
      : this.filterClientsWithTraining;
  }
  /**
   * Get current page title
   * @returns string
   */
  get getClasses() {
    return (position) => {
      if (typeof position !== "undefined") {
        return this.classes[position];
      }
      return this.classes;
    };
  }

  @Mutation
  [Mutations.PERSIST_WIDGET_OPEN_STATE]({ id, value }) {
    localStorage.setItem("widgetOpenState_" + id, value ? "true" : "false");
  }

  @Mutation
  [Mutations.SET_FILTER_CLIENTS_WITH_TRAINING](value: boolean) {
    this.filterClientsWithTraining = value;
    localStorage.setItem("filterClientsWithTraining", value ? "true" : "false");
  }
  @Mutation
  [Mutations.SET_MY_TRAININGS_COLLAPSED](value: boolean) {
    this.myTrainingsCollapsed = value;
    localStorage.setItem("myTrainingsCollapsed", value ? "true" : "false");
  }
  @Mutation
  [Mutations.SET_PAGE_IS_LOADING](value: boolean) {
    this.pageLoading = value;
  }

  @Mutation
  [Mutations.SET_SHOW_USER_SEARCH_IN_MENU](value: boolean) {
    this.userSearchInMenu = value;
  }

  @Mutation
  [Mutations.SET_CLASSNAME_BY_POSITION](payload) {
    const { position, className } = payload;
    if (!this.classes[position]) {
      this.classes[position] = [];
    }
    this.classes[position].push(className);
  }

  @Action
  [Actions.ADD_BODY_CLASSNAME](className) {
    if (className == "page-loading") {
      this.context.commit(Mutations.SET_PAGE_IS_LOADING, true);
    }
    document.body.classList.add(className);
  }

  @Action
  [Actions.REMOVE_BODY_CLASSNAME](className) {
    if (className == "page-loading") {
      this.context.commit(Mutations.SET_PAGE_IS_LOADING, false);
    }
    document.body.classList.remove(className);
  }

  @Action
  [Actions.ADD_BODY_ATTRIBUTE](payload) {
    const { qulifiedName, value } = payload;
    document.body.setAttribute(qulifiedName, value);
  }

  @Action
  [Actions.REMOVE_BODY_ATTRIBUTE](payload) {
    const { qulifiedName } = payload;
    document.body.removeAttribute(qulifiedName);
  }

  @Action
  [Actions.ADD_CLASSNAME](payload) {
    this.context.commit(Mutations.SET_CLASSNAME_BY_POSITION, payload);
  }
}
