export const exerciseCs = {
  exercises: {
    toolTips: {
      edit: "Upravit cvik {exercise}",
      delete: "Odstranit cvik {exercise}",
    },
    editTitle: "Upravit cvik",
    newTitle: "Nový cvik",
    addExercise: "Přidat cvik",
    errors: {
      notFound: "Cvik id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit cvik id {id}",
      nameExists: "Cvik s nazvem `{name}` již existuje",
      codeExists: "Cvik s kodem `{code}` již existuje",
      commonGET_EXERCISE_BY_ID: "Chyba při získávání dat o cvicích",
      commonDELETE_EXERCISE_BY_ID: "Chyba při odstraňování cviku",
      commonDELETE_EXERCISES: "Nepodařilo se odstranit cvik",
      cantGetExercises: "Chyba při získávání dat o cvicích",
    },
    form: {
      name: "Název",
      client_name: "Název pro klienta",
      color: "Barva",
      sports: "Sporty",
      code: "Kód",
      description: "Popis provádění",
      short_description: "Krátký popis",
      setup_description: "Popis nastavení",
      repeats: "Počet opakování",
      repeats_unit: "Jednotka opakování",
      duration: "Délka cvičení",
      duration_unit: "Jednotka",
      accessories: "Pomůcky",
      bodyParts: "Části těla",
      exerciseCategory: "Kategorie",
      image: "Obrázek",
      icon: "Ikona",
      video: "Video",
      company: "Firmy",
      exerciseChanged: "Cvik byl upraven",
      exerciseChangedFail: "Nepodařilo se upravit cvik",
      exerciseAdded: "Nový cvik byl vytvořen",
      exerciseAddFail: "Nepodařilo se vytvořit nový cvik",
    },
    table: {
      header: {
        name: "Název",
        icon: "Ikona",
        code: "Kód",
        accessories: "Pomůcky",
        exerciseCategory: "Kategorie",
        company: "Firmy",
        actions: "Akce",
      },
      deleteExerciseQuestion:
        "Opravdu chcete odstranit cvik <strong>{exercise}</strong>?",
      searchExercises: "Hledat cvik",
      exerciseDeleted: "Cvik {exercise} byl odstraněn",
      exercisesDeleted: "Cviky byly odstraněny",
      noRecords: "Nebyly nalezeny žádné cviky",
    },
  },
};
