import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PaginationResponse } from "@/store/interfaces/Datatables";
import { SortingPair } from "@/store/interfaces/Datatables";
import type { Tool } from "@/store/interfaces/Tool";

@Module
export default class ToolModule extends VuexModule {
  redirectTo = "";
  loadedTool: Tool = {} as Tool;
  Tools: Tool[] = [];
  pagination: PaginationResponse = {} as PaginationResponse;
  sorting: SortingPair = {} as SortingPair;

  get getLoadedTool(): Tool {
    return this.loadedTool.id
      ? this.loadedTool
      : ({
          ...this.loadedTool,
          ...{
            resources: this.loadedTool.resources
              ? this.loadedTool.resources
              : [
                  {
                    language_id: 1,
                    name: "",
                    client_name: "",
                    description: "",
                    tool_id: null,
                    id: null,
                    language: {
                      id: 1,
                      name: "Čeština",
                      code: "cs",
                      flag: "czech-republic",
                    },
                  },
                ],
          },
        } as Tool);
  }

  /**
   * Retreive current loaded tools
   */
  get getTools(): Tool[] {
    return this.Tools;
  }
  get getToolTypes(): Array<string> {
    return ["game", "measurement", "other"];
  }

  /**
   * Get last pagination setup
   */
  get getToolsPagination(): PaginationResponse {
    return this.pagination;
  }

  get getToolsSorting() {
    return this.sorting;
  }

  @Mutation
  [Mutations.SET_LOADED_TOOL](Tool: Tool) {
    this.loadedTool = Tool;
  }

  @Mutation
  [Mutations.SET_TOOLS](data) {
    this.Tools = data.data;
  }

  @Mutation
  [Mutations.SET_TOOLS_PAGINATION](pagination) {
    this.pagination = pagination;
  }

  @Mutation
  [Mutations.SET_TOOLS_SORTING](data) {
    this.sorting = data;
  }

  @Action
  [Actions.LOAD_TOOLS](payload) {
    ApiService.setHeader();
    return new Promise<Tool[]>((resolve, reject) => {
      ApiService.post("tools", payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_TOOLS, data["tools"]);
            this.context.commit(
              Mutations.SET_TOOLS_PAGINATION,
              data["tools"].pagination
            );
            resolve(data["tools"].data);
          } else {
            const i18string = data.i18;
            const i18params = {};
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "tools.errors.cantGetTools");
          reject();
        });
    });
  }
  @Action
  [Actions.GET_TOOL_BY_ID](id: number) {
    ApiService.setHeader();
    if (id == undefined) {
      return new Promise<Tool>((resolve) => {
        const Tool = {} as Tool;
        this.context.commit(Mutations.SET_LOADED_TOOL, Tool);
        resolve(Tool);
      });
    }
    return new Promise<Tool>((resolve, reject) => {
      ApiService.get(`tool/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_TOOL, data["tool"]);
            resolve(data["tool"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "tools.errors.commonGET_TOOL_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_TOOL_BY_ID](id: number) {
    ApiService.setHeader();
    return new Promise<Tool>((resolve, reject) => {
      ApiService.delete(`tool/${id}`)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_TOOL, data["tool"]);
            resolve(data["tool"]);
          } else {
            const i18string = data.i18;
            const i18params = { id };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "tools.errors.commonDELETE_TOOL_BY_ID"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_TOOL](Tool: Tool) {
    const payload = JSON.parse(JSON.stringify(Tool));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`tool/${Tool.id}`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_TOOL, data["tool"]);
            resolve();
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Tool.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "tools.form.toolChangedFail"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_TOOL](Tool: Tool) {
    const payload = JSON.parse(JSON.stringify(Tool));
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`tool`, payload)
        .then(({ data }) => {
          if (data.success) {
            this.context.commit(Mutations.SET_LOADED_TOOL, data["tool"]);
            resolve(data["tool"]);
          } else {
            const i18string = data.i18;
            const i18params = {
              id: Tool.id,
            };
            this.context.commit(Mutations.SET_ERROR, { i18string, i18params });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "tools.form.toolAddFail");
          reject();
        });
    });
  }

  @Action
  [Actions.GET_GAME_RESULTS_FOR_USER](userId: number) {
    ApiService.setHeader();
    return new Promise<object>((resolve, reject) => {
      ApiService.get(`tools/get-game-results-for-user/${userId}`)
        .then(({ data }) => {
          if (data.success) {
            resolve(data["results"]);
          } else {
            const i18string = data.i18;
            this.context.commit(Mutations.SET_ERROR, { i18string });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "tools.errors.cantGetGameResults"
          );
          reject();
        });
    });
  }

  @Action
  [Actions.STORE_GAME_RESULTS](result: object) {
    const payload = JSON.parse(JSON.stringify(result));
    ApiService.setHeader();
    return new Promise<object>((resolve, reject) => {
      ApiService.post(`tools/store-game-results`, payload)
        .then(({ data }) => {
          if (data.success) {
            resolve(result);
          } else {
            const i18string = data.i18;
            this.context.commit(Mutations.SET_ERROR, { i18string });
            reject();
          }
        })
        .catch(() => {
          this.context.commit(
            Mutations.SET_ERROR,
            "tools.errors.cantStoreGameResults"
          );
          reject();
        });
    });
  }
}
