export const declensionEn = {
  declensions: {
    day: {
      1: "day",
      2: "days",
      3: "days",
      4: "days",
      5: "days",
    },
    week: {
      1: "week",
      2: "weeks",
      3: "weeks",
      4: "weeks",
      5: "weeks",
    },
    month: {
      1: "month",
      2: "months",
      3: "months",
      4: "months",
      5: "months",
    },
    year: {
      1: "year",
      2: "years",
      3: "years",
      4: "years",
      5: "years",
    },
  },
};
