import { createI18n, useI18n } from "vue-i18n";
import { langEn } from "@/core/plugins/langs/en/app";
import { mailEn } from "@/core/plugins/langs/en/mail";
import { langCs } from "@/core/plugins/langs/cs/app";
import { mailCs } from "@/core/plugins/langs/cs/mail";
import { langSk } from "@/core/plugins/langs/sk/app";
import { mailSk } from "@/core/plugins/langs/sk/mail";

// Modules
import { userEn } from "@/core/plugins/langs/en/modules/user";
import { userCs } from "@/core/plugins/langs/cs/modules/user";
import { countryCs } from "@/core/plugins/langs/cs/modules/country";
import { countryEn } from "@/core/plugins/langs/en/modules/country";
import { userGroupCs } from "@/core/plugins/langs/cs/modules/user-group";
import { userGroupEn } from "@/core/plugins/langs/en/modules/user-group";
import { trainingCs } from "@/core/plugins/langs/cs/modules/training";
import { trainingEn } from "@/core/plugins/langs/en/modules/training";
import { trainingWeekCs } from "@/core/plugins/langs/cs/modules/trainingWeek";
import { trainingWeekEn } from "@/core/plugins/langs/en/modules/trainingWeek";
import { trainingEventCs } from "@/core/plugins/langs/cs/modules/trainingEvent";
import { trainingEventEn } from "@/core/plugins/langs/en/modules/trainingEvent";
import { specializationCs } from "@/core/plugins/langs/cs/modules/specialization";
import { specializationEn } from "@/core/plugins/langs/en/modules/specialization";
import { fileCs } from "@/core/plugins/langs/cs/modules/file";
import { fileEn } from "@/core/plugins/langs/en/modules/file";
import { toolCs } from "@/core/plugins/langs/cs/modules/tool";
import { toolEn } from "@/core/plugins/langs/en/modules/tool";
import { userGameDataCs } from "@/core/plugins/langs/cs/modules/user-game-data";
import { userGameDataEn } from "@/core/plugins/langs/en/modules/user-game-data";
import { companyCs } from "@/core/plugins/langs/cs/modules/company";
import { companyEn } from "@/core/plugins/langs/en/modules/company";
import { programCs } from "@/core/plugins/langs/cs/modules/program";
import { programEn } from "@/core/plugins/langs/en/modules/program";
import { widgetCs } from "@/core/plugins/langs/cs/modules/widgets";
import { widgetEn } from "@/core/plugins/langs/en/modules/widgets";
import { diagnosticCs } from "@/core/plugins/langs/cs/modules/diagnostic";
import { diagnosticEn } from "@/core/plugins/langs/en/modules/diagnostic";
import { sportCs } from "@/core/plugins/langs/cs/modules/sport";
import { sportEn } from "@/core/plugins/langs/en/modules/sport";
import { registrationTokenCs } from "@/core/plugins/langs/cs/modules/registartion-token";
import { registrationTokenEn } from "@/core/plugins/langs/en/modules/registartion-token";
import { companyBankAccountCs } from "@/core/plugins/langs/cs/modules/company-bank-account";
import { companyBankAccountEn } from "@/core/plugins/langs/en/modules/company-bank-account";
import { userContactEventsCs } from "@/core/plugins/langs/cs/modules/user-contact-event";
import { userContactEventsEn } from "@/core/plugins/langs/en/modules/user-contact-event";
import { accessoryCs } from "@/core/plugins/langs/cs/modules/accessory";
import { accessoryEn } from "@/core/plugins/langs/en/modules/accessory";
import { exerciseCs } from "@/core/plugins/langs/cs/modules/exercise";
import { exerciseEn } from "@/core/plugins/langs/en/modules/exercise";
import { exerciseCategoryCs } from "@/core/plugins/langs/cs/modules/exerciseCategory";
import { exerciseCategoryEn } from "@/core/plugins/langs/en/modules/exerciseCategory";
import { languageCs } from "@/core/plugins/langs/cs/modules/language";
import { languageEn } from "@/core/plugins/langs/en/modules/language";
import { notificationCs } from "@/core/plugins/langs/cs/modules/notification";
import { notificationEn } from "@/core/plugins/langs/en/modules/notification";
import { invoiceCs } from "@/core/plugins/langs/cs/modules/invoice";
import { invoiceEn } from "@/core/plugins/langs/en/modules/invoice";
import { bodyPartCs } from "@/core/plugins/langs/cs/modules/bodyPart";
import { bodyPartEn } from "@/core/plugins/langs/en/modules/bodyPart";
import { userSk } from "@/core/plugins/langs/sk/modules/user";
import { countrySk } from "@/core/plugins/langs/sk/modules/country";
import { userGroupSk } from "@/core/plugins/langs/sk/modules/user-group";
import { trainingSk } from "@/core/plugins/langs/sk/modules/training";
import { trainingWeekSk } from "@/core/plugins/langs/sk/modules/trainingWeek";
import { trainingEventSk } from "@/core/plugins/langs/sk/modules/trainingEvent";
import { specializationSk } from "@/core/plugins/langs/sk/modules/specialization";
import { fileSk } from "@/core/plugins/langs/sk/modules/file";
import { toolSk } from "@/core/plugins/langs/sk/modules/tool";
import { userGameDataSk } from "@/core/plugins/langs/sk/modules/user-game-data";
import { companySk } from "@/core/plugins/langs/sk/modules/company";
import { programSk } from "@/core/plugins/langs/sk/modules/program";
import { widgetSk } from "@/core/plugins/langs/sk/modules/widgets";
import { diagnosticSk } from "@/core/plugins/langs/sk/modules/diagnostic";
import { sportSk } from "@/core/plugins/langs/sk/modules/sport";
import { registrationTokenSk } from "@/core/plugins/langs/sk/modules/registartion-token";
import { companyBankAccountSk } from "@/core/plugins/langs/sk/modules/company-bank-account";
import { userContactEventsSk } from "@/core/plugins/langs/sk/modules/user-contact-event";
import { accessorySk } from "@/core/plugins/langs/sk/modules/accessory";
import { exerciseSk } from "@/core/plugins/langs/sk/modules/exercise";
import { exerciseCategorySk } from "@/core/plugins/langs/sk/modules/exerciseCategory";
import { languageSk } from "@/core/plugins/langs/sk/modules/language";
import { notificationSk } from "@/core/plugins/langs/sk/modules/notification";
import { invoiceSk } from "@/core/plugins/langs/sk/modules/invoice";
import { bodyPartSk } from "@/core/plugins/langs/sk/modules/bodyPart";
import { unitSk } from "@/core/plugins/langs/sk/modules/unit";
import { unitEn } from "@/core/plugins/langs/en/modules/unit";
import { unitCs } from "@/core/plugins/langs/cs/modules/unit";
import { exerciseExecutionSk } from "@/core/plugins/langs/sk/modules/exerciseExecution";
import { exerciseExecutionEn } from "@/core/plugins/langs/en/modules/exerciseExecution";
import { exerciseExecutionCs } from "@/core/plugins/langs/cs/modules/exerciseExecution";
import { paymentCs } from "@/core/plugins/langs/cs/modules/payment";
import { paymentSk } from "@/core/plugins/langs/sk/modules/payment";
import { paymentEn } from "@/core/plugins/langs/en/modules/payment";
import { trainingTemplateCs } from "@/core/plugins/langs/cs/modules/trainingTemplate";
import { trainingTemplateSk } from "@/core/plugins/langs/sk/modules/trainingTemplate";
import { trainingTemplateEn } from "@/core/plugins/langs/en/modules/trainingTemplate";
import { declensionEn } from "@/core/plugins/langs/en/modules/declension";
import { declensionCs } from "@/core/plugins/langs/cs/modules/declension";
import { declensionSk } from "@/core/plugins/langs/sk/modules/declension";

const messages = {
  en: {
    ...langEn,
    ...mailEn,
    ...userEn,
    ...countryEn,
    ...userGroupEn,
    ...trainingEn,
    ...trainingWeekEn,
    ...trainingEventEn,
    ...specializationEn,
    ...fileEn,
    ...toolEn,
    ...userGameDataEn,
    ...companyEn,
    ...programEn,
    ...widgetEn,
    ...diagnosticEn,
    ...sportEn,
    ...registrationTokenEn,
    ...userContactEventsEn,
    ...accessoryEn,
    ...languageEn,
    ...notificationEn,
    ...companyBankAccountEn,
    ...invoiceEn,
    ...exerciseEn,
    ...exerciseCategoryEn,
    ...bodyPartEn,
    ...unitEn,
    ...exerciseExecutionEn,
    ...paymentEn,
    ...trainingTemplateEn,
    ...declensionEn,
  },
  cs: {
    ...langCs,
    ...mailCs,
    ...userCs,
    ...countryCs,
    ...userGroupCs,
    ...trainingCs,
    ...trainingWeekCs,
    ...trainingEventCs,
    ...specializationCs,
    ...fileCs,
    ...toolCs,
    ...userGameDataCs,
    ...companyCs,
    ...programCs,
    ...widgetCs,
    ...diagnosticCs,
    ...sportCs,
    ...registrationTokenCs,
    ...userContactEventsCs,
    ...accessoryCs,
    ...languageCs,
    ...notificationCs,
    ...companyBankAccountCs,
    ...invoiceCs,
    ...exerciseCs,
    ...exerciseCategoryCs,
    ...bodyPartCs,
    ...unitCs,
    ...exerciseExecutionCs,
    ...paymentCs,
    ...trainingTemplateCs,
    ...declensionCs,
  },
  sk: {
    ...langSk,
    ...mailSk,
    ...userSk,
    ...countrySk,
    ...userGroupSk,
    ...trainingSk,
    ...trainingWeekSk,
    ...trainingEventSk,
    ...specializationSk,
    ...fileSk,
    ...toolSk,
    ...userGameDataSk,
    ...companySk,
    ...programSk,
    ...widgetSk,
    ...diagnosticSk,
    ...sportSk,
    ...registrationTokenSk,
    ...userContactEventsSk,
    ...accessorySk,
    ...languageSk,
    ...notificationSk,
    ...companyBankAccountSk,
    ...invoiceSk,
    ...exerciseSk,
    ...exerciseCategorySk,
    ...bodyPartSk,
    ...unitSk,
    ...exerciseExecutionSk,
    ...paymentSk,
    ...trainingTemplateSk,
    ...declensionSk,
  },
};

const dateTimeFromats = {
  en: "DD.MM.YYYY HH:mm:ss",
  cs: "DD.MM.YYYY HH:mm:ss",
  sk: "DD.MM.YYYY HH:mm:ss",
};

const dateFromats = {
  en: "DD.MM.YYYY",
  cs: "DD.MM.YYYY",
  sk: "DD.MM.YYYY",
};

const browserLocale = localStorage.lang
  ? localStorage.lang
  : navigator.language.split("-")[0];
const defaultLocale = Object.prototype.hasOwnProperty.call(
  messages,
  browserLocale
)
  ? browserLocale
  : "en";
const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  globalInjection: true,
  warnHtmlMessage: false, // disable warning HTML in message
  messages,
});

export const getMailTemplate = (template: string) => {
  const mailTemplate = JSON.parse(
    JSON.stringify(useI18n().getLocaleMessage(useI18n().locale.value))
  ).mails[template];
  return mailTemplate ?? {};
};
export const getCurrentDateTimeFormat = () => {
  return dateTimeFromats[i18n.global.locale.value] ?? dateTimeFromats.en;
};

export const getCurrentDateFormat = () => {
  return dateFromats[i18n.global.locale.value] ?? dateFromats.en;
};

export default i18n;
